<template>
  <div v-if="client" class="contacts-send">
    <form action="/" @submit.prevent="submit">
      <h5>Enviar para:</h5>
      <ul>
        <li>
          <div class="square" :class="{selected: sendTo.length == contacts.length}" @click="selectAll">
            <svg-icon icon="tick"></svg-icon>
          </div>
          <strong>Todos</strong>
        </li>
        <li v-for="contact in contacts" :key="contact.id">
          <div class="square" :class="{selected: sendTo.includes(contact.id)}" @click="select(contact.id)">
            <svg-icon icon="tick"></svg-icon>
          </div>
          <strong v-text="contact.name"></strong>
          -
          <span v-text="contact.email"></span>
        </li>
      </ul>
      <editor v-model="message"></editor>
      <div v-if="invoice">
        Em anexo: <span v-text="invoice.description"></span> [<span v-text="invoice.filename"></span>]
      </div>
      <div class="actions">
        <button type="submit">
          <svg-icon icon="paper-plane"></svg-icon>
          <span>Enviar</span>
        </button>
        <router-link class="cancel" tag="button" :to="{name: 'adminClient', params: {client_id: client_id}}">
          Cancelar
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SvgIcon from '../SvgIcon.vue';
import Scroll from '../Scroll.vue';
import Editor from '../Editor.vue';

export default {
  components: {
    SvgIcon, Scroll, Editor
  },
  props: {
    client_id: {required: true},
    invoice_id: {required: true},
  },
  data() {
    return {
      message: '',
      sendTo: [],
    }
  },
  computed: {
    ...mapGetters({
      clientById: 'clients/client',
      contactsByClient: 'contacts/client',
    }),
    client() {
      return this.clientById(this.client_id);
    },
    contacts() {
      return this.contactsByClient(this.client_id);
    },
    invoice() {
      return this.$parent.invoices.find(({id}) => id == this.invoice_id);
    }
  },
  methods: {
    selectAll() {
      console.log(this.sendTo);
      console.log(this.contacts);
      if( this.sendTo.length == this.contacts.length ) {
        this.sendTo = [];
      } else {
        this.sendTo = this.contacts.map((contact) => contact.id);
      }
    },
    select(contact_id) {
      let index = this.sendTo.indexOf(contact_id);
      if(index > -1) {
        this.sendTo.splice(index, 1);
      } else {
        this.sendTo.push(contact_id);
      }
    },
    submit() {
      this.$store.dispatch('contacts/send', {
        subject: this.invoice.description,
        contacts: this.sendTo,
        invoice_id: this.invoice.id,
        client_id: this.client_id,
        content: this.message,
      });
      this.$router.push({name: 'adminClient', params: {client_id: this.client_id}});
    },
  },
  watch: {
    contacts() {
      this.selectAll();
    }
  },
  mounted() {
    this.selectAll();
    this.message = `<p>Exmos. Srs. <strong>${this.client.name}</strong><br>Em anexo remetemos a fatura referente a <strong>${this.invoice.description}</strong>.</p><p><br></p><p>Aproveitamos para indicar que este documento, bem como o restante histórico pendente e liquidado, pode ser consultado dentro da nossa plataforma <a href="https://dev.blatstudio.com">dev.blatstudio.com</a>.</p>`;
  },
  beforeDestroy() {
  },
}
</script>