import axios from 'axios'
window.axios = axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.interceptors.request.use(function (config) {
  if( config.url === undefined ) {
    console.log(config);
    throw "ERROR";
  }
  if(config.url.endsWith('/')) {
    config.url = config.url.slice(0, -1);
  }
  if(!config.url.endsWith('.json')) {
    config.url += ".json"
  }
  // config.params = {...config.params, format: 'json'};
  return config;
}, function (error) {
  return Promise.reject(error);
});
let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found');
}

export default axios
