<template>
    <div id="project-files" v-if="project">
        <h3 class="title">Ficheiros</h3>
        <template v-if="converted">
            <div class="menu files" v-if="files">
                <scroll>
                <h4>Documentos (<span v-text="documentsSize"></span>)</h4>
                <ul>
                    <li v-for="document in documents" :key="document.id" :class="{final: isFinal(document)}">
                        <i class="star" @click.prevent="star(document)">
                            <svg-icon icon="flag"></svg-icon>
                        </i>
                        <a :href="document.url.original" target="_blank">
                            <p v-text="document.filename"></p>
                        </a>
                        <i class="trash" @click.prevent="destroy(document)" v-if="!isFinal(document)">
                            <svg-icon icon="trash"></svg-icon>
                        </i>
                    </li>
                </ul>
                <h4>Imagens (<span v-text="imagesSize"></span>)</h4>
                <ul class="images">
                    <span class="filename" ref="filename" :style="filenameStyle" v-text="filename"></span>
                    <li v-for="image in images"
                        :key="image.id"
                        :class="{final: isFinal(image)}"
                        @mouseover="hovering($event, image)"
                        @mouseleave="hovering($event)">
                        <i class="star" @click.prevent="star(image)">
                            <svg-icon icon="flag"></svg-icon>
                        </i>
                        <i class="trash" @click.prevent="destroy(image)" v-if="!isFinal(image)">
                            <svg-icon icon="trash"></svg-icon>
                        </i>
                        <router-link :to="{name: 'adminProjectPreview', params: {global_id: image.global_id}}" v-if="image.global_id">
                            <image-loader :src="image.url_thumb || image.url.thumb" :alt="image.file_name || image.filename"></image-loader>
                        </router-link>
                        <a href="" v-else @click.prevent>
                            <image-loader :src="image.url_thumb || image.url.thumb" :alt="image.file_name || image.filename"></image-loader>
                        </a>
                    </li>
                </ul>
                </scroll>
            </div>
        </template>
        <button v-else-if="!converting" @click.prevent="queueConversion">Converter ficheiros</button>
        <h3 v-else>
            A converter...
        </h3>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Scroll from '../Scroll.vue';
import ImageLoader from '../ImageLoader.vue';
import SvgIcon from '../SvgIcon.vue';

import {app} from '../../packs/firebase/fb'
import { getStorage, ref as storageRef, getDownloadURL, getMetadata } from 'firebase/storage'
const storage = getStorage(app)
import { getFirestore, collection, onSnapshot, deleteDoc, updateDoc } from 'firebase/firestore'
const firestore = getFirestore(app)

export default {
  components: {
    Scroll, ImageLoader, SvgIcon
  },
  props: {
    project_id: {required: true}
  },
  data() {
    return {
        files: null,
        firebaseFiles: [],
        firebaseImages: [],
        firebaseFinal: [],
        fetchingFiles: null,
        filename: '',
        filenamePosition: {x: 0, y: 0},
        filenameTimeout: null,
        unsub: null,
        allFiles: [],
    }
  },
  methods: {
      async setup() {
          if( this.unsub != null ) {
              await this.unsub()
          }
          this.firebaseImages = []
          this.firebaseFiles = []
          let filesCollection = collection(firestore, "projects", this.project_id.toString(), "files")
          this.unsub = onSnapshot(filesCollection, async (snapshot) => {
              for( let change of snapshot.docChanges() ) {
                  switch(change.type) {
                      case "added":
                          this.addFile(change.doc.id, change.doc)
                          break
                      case "modified":
                          this.addFile(change.doc.id, change.doc)
                          break
                      case "removed":
                          this.remFile(change.doc.id, change.doc)
                          break
                  }
              }
          })
      },
      async addFirebaseImage(id, doc) {
          const data = doc.data()
          let fileData = {
              refs: {},
              url: {},
              is_final: data.final,
          }
          let ref = storageRef(storage, data.path)
          fileData.data = data
          fileData.meta = await getMetadata(ref)
          fileData.refs.original = ref
          fileData.url.original = await getDownloadURL(ref)
          let mediumRef = storageRef(storage, data.medium)
          fileData.url.medium = await getDownloadURL(mediumRef)
          let thumbRef = storageRef(storage, data.thumb)
          fileData.url.thumb = await getDownloadURL(thumbRef)
          fileData.contentType = fileData.meta.contentType
          fileData.filename = fileData.meta.namefilesvue
          fileData.byte_size = fileData.meta.size
          fileData.id = id
          fileData.global_id = id
          fileData.doc = doc.ref
          this.remFile(id)
          this.firebaseImages.push(fileData)
      },
      async addFirebaseFile(id, doc) {
          const data = doc.data()
          let fileData = {
              refs: {},
              url: {},
              is_final: data.final,
          }
          let ref = storageRef(storage, data.path)
          fileData.data = data
          fileData.meta = await getMetadata(ref)
          fileData.refs.original = ref
          fileData.url.original = await getDownloadURL(ref)
          fileData.contentType = fileData.meta.contentType
          fileData.filename = fileData.meta.name
          fileData.byte_size = fileData.meta.size
          fileData.id = id
          fileData.global_id = id
          fileData.doc = doc.ref
          this.remFile(id)
          this.firebaseFiles.push(fileData)
      },
      async addFile(id, doc) {
          const data = doc.data()
          if( data.thumb != null ) {
              this.addFirebaseImage(id, doc)
          } else {
              this.addFirebaseFile(id, doc)
          }
      },
      remFile(id) {
          let index = this.firebaseFiles.findIndex((file) => file.id == id)
          if( index > -1 ) {
            this.firebaseFiles.splice(index, 1)
          }
          index = this.firebaseImages.findIndex((file) => file.id == id)
          if( index > -1 ) {
            this.firebaseImages.splice(index, 1)
          }
      },
    hovering(e, image = null) {
      clearTimeout(this.filenameTimeout);
      this.filenameTimeout = null;
      if( image == null ) {
        this.filenameTimeout = setTimeout(() => this.filename = '', 500);
      } else {
        this.filename = image.filename;
        let target = e.target;
        if(target.tagName.toUpperCase() == "A") {
          let li = target.parentElement;
          let xStart = li.offsetLeft;
          let xEnd = li.offsetWidth + xStart;
          let xMiddle = (xStart + xEnd) / 2;
          let ul = li.parentElement;
          let xArea = ul.offsetWidth;
          let xPosition = xMiddle * 100 / xArea;
          let yStart = li.offsetTop;
          this.filenamePosition = {x: xPosition, y: yStart};
        }
      }
    },
      async destroy(file) {
          if( file.doc == null ) {
              let url = `/api/files/${file.id}`;
              axios.delete(url)
                  .then( response => {
                      this.fetchFiles();
                  })
            return
          }
          console.log(file.doc)
          await deleteDoc(file.doc)
      },
    queueConversion() {
      this.$store.dispatch('projects/convert', this.project);
    },
    isFinal(image) {
        if( image.is_final ) {
            return true
        }
        return this.files.final_arts.includes(image.global_id);
    },
      star(image) {
          if( image.doc == null ) {
              let url = `${this.project.url}/final_arts`
              if(this.isFinal(image)) {
                  axios.delete(url, {params: {
                      global_id: image.global_id
                  }})
                      .then( response => {
                          this.files = response.data;
                      })
              } else {
                  axios.post(url, {
                      global_id: image.global_id
                  }).then( response => {
                      this.files = response.data;
                  })
              }
              return
          }
          console.log(image.doc)
          updateDoc(image.doc, {final: !image.is_final}, {merge: true})
      },
    fetchFiles() {
      clearTimeout(this.fetchingFiles)
      this.fetchingFiles = setTimeout( () => {
        if(this.project == null) {
          this.fetchFiles();
        } else {
          let id = this.project_id;
          axios.get(`${this.project.url}/files`)
          .then( response => {
            if( this.project_id === id) {
              this.files = response.data;
            }
          })
          .catch( error => {
            console.log(error);
          });
        }
      }, 200)
    },
  },
    watch: {
        project: {
            immediate: true,
            handler() {
                this.fetchFiles()
            },
        },
        project_id: {
            immediate: true,
            handler() {
                this.setup()
            },
        },
    },
    computed: {
        ...mapGetters(
            { clientProject: 'projects/project' }
        ),
        converting() {
            if( this.project && this.files ) {
                return !(this.project.convertion_state == null || this.project.convertion_state == 'false');
            }
            return true;
        },
        converted() {
            if( this.project && this.files ) {
                return this.project.convertion_state == 'converted';
            }
            return true;
        },
        documentsAWS() {
            if(this.files) {
                return this.files.documents;
            }
            return [];
        },
        documents() {
            return [...this.documentsAWS, ...this.sortedFirebaseFiles]
        },
        documentsSize() {
            let size = this.documents.reduce((total, currentValue) => total + currentValue.byte_size, 0 )
            return this.$root.readableBytes(size);
        },
        filenameStyle() {
            let style = { bottom: `calc(100% - ${this.filenamePosition.y - 5}px)` };
            if( this.filenameTimeout != null || this.filename == '' ) {
                style.opacity = 0;
            }
            if( this.filenamePosition.x > 50 ) {
                style.right = 0;
            } else {
                style.left = 0;
            }
            return style;
        },
        filesRef() {
            return storageRef(storage, `projects/${this.project_id}/files`)
        },
        project() {
            return this.clientProject(this.project_id)
        },
        imagesAWS() {
            if(this.files) {
                return this.files.images;
            }
            return [];
        },
        images() {
            return [...this.imagesAWS, ...this.sortedFirebaseImages]
        },
        imagesRef() {
            return storageRef(storage, `projects/${this.project_id}/images`)
        },
        imagesSize() {
            let size = this.images.reduce((total, currentValue) => total + currentValue.byte_size, 0 )
            return this.$root.readableBytes(size);
        },
        sortedFirebaseImages() {
            return this.firebaseImages.sort((a,b) => {
                if( a.data.createdAt == null ) {
                    return -1
                }
                if( b.data.createdAt == null ) {
                    return 1
                }
                return a.data.createdAt.toMillis() - b.data.createdAt.toMillis()
            })
        },
        sortedFirebaseFiles() {
            return this.firebaseFiles.sort((a,b) => {
                if( a.data.createdAt == null ) {
                    return -1
                }
                if( b.data.createdAt == null ) {
                    return 1
                }
                return a.data.createdAt.toMillis() - b.data.createdAt.toMillis()
            })
        },
    },
    async mounted() {
    },
    beforeDestroy() {
        if( this.unsub != null ) {
            this.unsub()
        }
    }
}
</script>
