<template>
  <div v-if="client" class="client-show">
    <router-view></router-view>
    <h3 class="title" v-text="client.name"></h3>
    <div class="info">
      <scroll>
        <image-loader :src="client.logo.medium" :alt="client.name"></image-loader>
        <dl>
          <dt>Nome</dt>
          <dd v-text="client.name"></dd>
          <dt>Morada</dt>
          <dd v-text="client.address"></dd>
          <dt>NIF</dt>
          <dd v-text="client.taxid"></dd>
        </dl>
        <div class="contacts">
          <h4>
            Contactos
          </h4>
          <contact-list :client_id="client_id"></contact-list>
          <dl>
            <template v-for="contact in contacts">
              <dt v-text="contact.name" :key="contact.id"></dt>
              <dd v-text="contact.email" :key="contact.id"></dd>
            </template>
          </dl>
        </div>
      </scroll>
    </div>
    <invoice-list class="paid"
      title="Fechado"
      :invoices="paidInvoices"
      :client_id="client_id"
      :paid="true"
      @update="update"
      @destroy="destroy"
    ></invoice-list>
    <invoice-list class="unpaid"
      title="Pendente"
      :client_id="client_id"
      :invoices="unpaidInvoices"
      :paid="false"
      @update="update"
      @destroy="destroy"
    ></invoice-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Scroll from '../Scroll.vue';
import ImageLoader from '../ImageLoader.vue';
import SvgIcon from '../SvgIcon.vue';
import InvoiceList from './InvoiceList.vue';
import ContactList from '../contacts/Index.vue';

export default {
  components: {
    Scroll, ImageLoader, SvgIcon, InvoiceList, ContactList
  },
  props: {
    client_id: {required: true},
    route: { default: 'adminClientProjects' },
  },
  data() {
    return {
      invoices: [],
      unpaidSelected: [],
      draggingToPaid: 0,
      attachedFile: null,
      billDescription: '',
      progress: 0,
      contacts: [],
    }
  },
  computed: {
    ...mapGetters({
      clientById: 'clients/client',
    }),
    client() {
      return this.clientById(this.client_id);
    },
    paidInvoices() {
      return this.invoices.filter( ({paid}) => paid );
    },
    unpaidInvoices() {
      return this.invoices.filter( ({paid}) => !paid );
    },
    unpaidInvoicesString() {
      return `A arrastar ${this.unpaidSelected.length}`;
    },
    filename() {
      if( this.attachedFile ) {
        return this.attachedFile.name;
      }
    },
  },
  methods: {
    attachFile() {
      this.$refs.file.click();
    },
    handleFiles() {
      let files = this.$refs.file.files;
      this.attachedFile = files[0];
    },
    destroy(invoice) {
      axios.delete(`/api/companies/${this.client_id}/invoices/${invoice.id}`)
      .then( response => {
          let index = this.invoices.findIndex( ({id}) => id == invoice.id );
          if( index > -1) {
              this.invoices.splice(index, 1);
          }
      });
    },
    drag($event, invoice) {
      this.selectInvoice(invoice, true);
      $event.dataTransfer.setData('invoice', invoice.id);
      $event.dataTransfer.setDragImage(this.$refs.unpaidDrag, 0, 0);
    },
    dragged($event) {
      let invoiceId = $event.dataTransfer.getData('invoice');
      if( invoiceId != "" ) {
        this.draggingToPaid = 0;
        for (let i = 0; i < this.unpaidSelected.length; i++) {
          let invoice = this.unpaidSelected[i];
          invoice.paid = !invoice.paid;
        }
        this.unpaidSelected = [];
      }
    },
    dragleaving($event) {
      let invoiceId = $event.dataTransfer.getData('invoice');
      if( invoiceId != "" ) {
        this.draggingToPaid--;
      }
    },
    dragging($event) {
      let invoiceId = $event.dataTransfer.getData('invoice');
      if( invoiceId != "" ) {
        this.draggingToPaid++;
      }
    },
    fetchInvoices() {
      axios.get(`/api/companies/${this.client_id}/invoices`)
      .then( response => this.invoices = response.data );
    },
    invoiceSelected(invoice) {
      return this.unpaidSelected.includes(invoice);
    },
    selectInvoice(invoice, force = false) {
      if( invoice.paid ) {
      } else {
          let index = this.unpaidSelected.findIndex( ({id}) => id == invoice.id );
          if( index > -1) {
              this.unpaidSelected.splice(index, 1);
          }
          if( force || (index < 0) ) {
            this.unpaidSelected.push(invoice);
          }
      }
    },
    selectAll(invoices) {
      if( invoices === this.unpaidInvoices ) {
        if( this.unpaidSelected.length > 0 ) {
          this.unpaidSelected = [];
          return;
        }
      } else {
        if( this.paidSelected.length > 0 ) {
          this.paidSelected = [];
          return;
        }
      }
      for (let i = 0; i < invoices.length; i++) {
        const invoice = invoices[i];
        this.selectInvoice(invoice, true);
      }
    },
    update(obj) {
      let invoice = obj.invoice;
      let params = {invoice: obj.params};
      axios.patch(`/api/companies/${this.client_id}/invoices/${invoice.id}`, params)
      .then( response => {
        let newInv = response.data;
          let index = this.invoices.findIndex( ({id}) => id == newInv.id );
          if( index > -1) {
              this.invoices.splice(index, 1);
          }
          this.invoices.unshift(newInv);
      });
    },
  },
  watch: {
    client_id() {
      this.fetchInvoices();
    }
  },
  mounted() {
    this.$store.dispatch('clients/fetchAll');
    this.$store.dispatch('projects/fetchOpen');
    this.fetchInvoices();
  },
  beforeDestroy() {
  },
}
</script>