import htmx from "htmx.org"
import { getStorage, ref, uploadBytes } from "firebase/storage"
import {app} from "./fb.js"

const storage = getStorage(app)

let reg = /\/projects\/\d+/
let projectPath = reg.exec(window.location.pathname)
let projectId = null
if( projectPath != null ) {
    projectId = parseInt(projectPath[0].replace("/projects/",""))
}


document.addEventListener('DOMContentLoaded', () => {
    let dropZones = htmx.findAll(".dropzone-2")
    console.log(dropZones)

    dropZones.forEach( (dropZone) => {
        let processing = false
        let fileList = []

        function addFiles(files) {
            [...files].forEach((file) => {
                let el = document.createElement("div")
                el.innerHTML = `${file.name}<br><strong>In Queue</strong>`
                dropZone.appendChild(el)
                fileList.push({
                    file,
                    el,
                })
            })
            processFiles()
        }

        async function processFiles() {
            if( processing ) {
                return
            }
            processing = true
            if( fileList.length == 0 ) {
                processing = false
                return
            }
            let file = fileList.shift()
            file.el.innerHTML = `${file.file.name}:<br><strong>Uploading</strong>`
            const storageRef = ref(storage, `projects/${projectId}/new/${file.file.name}`);
            await uploadBytes(storageRef, file.file)
            file.el.innerHTML = `${file.file.name}:<br><strong>Done</strong>`
            processing = false
            processFiles()
        }

        let input = dropZone.querySelector('input[type="file"]')
        htmx.on(input, "change", () => {
            addFiles(input.files)
        })

        htmx.on(dropZone, "dragover", (e) => {
            e.preventDefault()
        })
        htmx.on(dropZone, "dragenter", (e) => {
            htmx.addClass(dropZone, "dragover")
        })
        htmx.on(dropZone, "dragleave", () => {
            htmx.removeClass(dropZone, "dragover")
        })
        htmx.on(dropZone, "drop", (e) => {
            e.preventDefault()
            htmx.removeClass(dropZone, "dragover")
            addFiles(e.dataTransfer.files)
        })
        htmx.on(dropZone, "click", () => {
            input.click()
        })
    })
})
