import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import clients from './clients';
import projects from './projects';
import users from './users';
import focus from './focus';
import messages from './messages';
import contacts from './contacts';

export const store = new Vuex.Store({
    state: {
        count: 0
    },
    mutations: {
        increment (state) {
            state.count++
        }
    },
    modules: {
        contacts: contacts,
        clients: clients,
        projects: projects,
        users: users,
        focus: focus,
        messages: messages,
    }
})
