<template>
  <div id="clients" :class="{collapsed: collapsed}">
    <h3 class="title">Clientes</h3>
    <div class="menu">
      <scroll>
        <ul>
          <router-link tag="li" v-for="client in clients" :key="client.id"
            :to="{name: route, params: {client_id: client.id}}">
            <a>
              <image-loader :src="client.logo.thumb" :alt="client.name"></image-loader>
              <span v-text="client.name" v-if="!collapsed"></span>
              <span class="number" v-text="unread(client)" v-if="unread(client) > 0"></span>
            </a>
          </router-link>
        </ul>
      </scroll>
    </div>
    <a @click.prevent="collapsed = !collapsed" class="collapse">
      <svg>
        <use :xlink:href="icons + '#' + icon" :href="icons + '#' + icon"></use>
      </svg>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Scroll from '../Scroll.vue';
import ImageLoader from '../ImageLoader.vue';
import Icons from 'images/icons.svg';

export default {
  components: {
    Scroll, ImageLoader
  },
  props: {
    icons: {default: Icons},
    route: { default: 'adminClientProjects' },
  },
  data() {
    return {
      collapsed: false,
      icon:"double-chevron-down",
      notificationsSubscription: null,
    }
  },
  computed: {
    ...mapGetters({
      unreadProjects: 'projects/clientUnread',
    }),
    clients() {
      return this.$store.state.clients.all
    }
  },
  methods: {
    unread(client) {
      return this.unreadProjects(client.id).length;
    },
    subscribe() {
      let vue = this;
      if( this.notificationsSubscription != null ) {
        this.$root.cable.subscriptions.remove(this.notificationsSubscription);
      }
      this.notificationsSubscription = this.$root.cable.subscriptions.create({
        channel: "NotificationsChannel"
      }, {
        received(data) {
          if( data.type ) {
            switch (data.type) {
              case "message":
                vue.$store.commit('messages/add', data.message);
                vue.$store.dispatch('projects/fetch', {id: data.message.project_id});
                break;

              case "delete":
                vue.$store.commit('messages/remove', data.message);
                break;
            
              default:
                break;
            }
          }
        }
      })
    },
  },
  mounted() {
    this.$store.dispatch('clients/fetchAll');
    this.$store.dispatch('projects/fetchOpen');
    this.subscribe();
  },
  beforeDestroy() {
    this.$root.cable.subscriptions.remove(this.notificationsSubscription);
  },
}
</script>