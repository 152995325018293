<template>
  <div id="hidden">
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      message: "Hello Vue!"
    }
  },
  mounted() {
    this.$root.cable.subscriptions.create({
      channel: "NotificationsChannel"
    }, {
      received: function(data) {
        if(data.audio) {
          notifyMe(data);
          var audio = new Audio(data.audio);
          audio.play();
          console.log("FROM VUE");
          console.log(data);
          setTimeout(function() {
            location.reload();
          }, 150);
        }
      }
    });
  }
}
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
