<template>
  <div id="project-information" v-if="project">
    <h3 class="title">Informação</h3>
    <div class="info">
      <scroll>
        <h4>Descrição</h4>
        <div v-text="project.description"></div>
        <h4>Elementos</h4>
        <div v-text="project.format_type"></div>
      </scroll>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Scroll from '../Scroll.vue';

export default {
  components: {
    Scroll
  },
  props: {
    project_id: {required: true}
  },
  data: function () {
    return {
    }
  },
  methods: {
  },
  watch: {
  },
  computed: {
    ...mapGetters({ clientProject: 'projects/project' }),
    project() { return this.clientProject(this.project_id) },
  },
  mounted() {
  },
  beforeDestroy() {
  }
}
</script>