<template>
  <div class="image" :class="{error: error, loaded: loaded, loading: !loaded}">
    <transition name="fade">
      <img :src="loadingSrc" v-if="!(error || loaded)" class="loading-icon">
    </transition>
    <transition name="fade" v-if="!error" >
      <img @error="loadFailed" @load="loadComplete" :src="src" v-show="loaded">
    </transition>
    <transition name="fade" v-else>
      <svg class="error-icon">
        <use :xlink:href="errorIcon" :href="errorIcon"></use>
      </svg>
    </transition>
  </div>
</template>

<script>
import Icons from 'images/icons.svg';

export default {
  props: {
    icons: {default: Icons},
    src: { required: true },
    icon: { default: 'grid' },
    error_icon: { default: 'danger' }
  },
  data: function() {
    return {
      error: false,
      loaded: false,
    }
  },
  methods: {
    loadComplete() {
      this.error = false;
      this.loaded = true;
    },
    loadFailed() {
      this.loaded = true;
      this.error = true;
    },
  },
  computed: {
    errorIcon() {
      return `${this.icons}#${this.error_icon}`;
    },
    loadingSrc() {
      return `/images/loaders/${this.icon}.svg`;
    }
  },
  mounted() {
  }
}
</script>