<template>
    <div id="admin">
        <Top></Top>
        <router-view></router-view>
    </div>
</template>

<script>
    import Clients from './clients/clients.vue';
    import Top from './menus/Top.vue';
    export default {
        components: {
            Clients, Top
        },
        data: function () {
            return {
            }
        },
        mounted() {
            this.$root.cable.subscriptions.create({
                channel: "PresenceChannel"
            }, {
                received(data) {
                    if( data.online == null ) return
                    data.online.forEach( id => {
                        this.$store.dispatch('users/fetch', id);
                    });
                    this.$store.commit('users/online', data.online);
                }
            });
        }
    }
</script>
