<template>
  <svg>
    <use :xlink:href="iconHref" :href="iconHref"></use>
  </svg>
</template>

<script>
import Icons from 'images/icons.svg';

export default {
  props: {
    icon: {required: true},
    icons: {default: Icons},
  },
  data: function() {
    return {
    }
  },
  methods: {
  },
  computed: {
    iconHref() {
      return `${this.icons}#${this.icon}`;
    }
  },
  mounted() {
  }
}
</script>