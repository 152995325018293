<template>
<div class="editor" :class="{'has-text': message != ''}">
  <div class="placeholder">
    Escreva aqui a sua mensagem
  </div>
  <scroll class="input">
    <div class="text" @keydown="keyInput" @input="textChange" contenteditable ref="text">
      <p><br></p>
    </div>
  </scroll>
  <ul class="actions" v-if="actions">
    <li class="notification" :class="{closed: companyUpdated, secondary: project.company_updated}" @click="notify">
      <label>
        <svg-icon icon="horn"></svg-icon>
      </label>
    </li>
    <li class="attachment">
      <input type="file" id="files" ref="file" multiple @change="handleFiles"/>
      <label for="files">
        <svg-icon icon="attach"></svg-icon>
      </label>
    </li>
    <li class="send" :class="{closed: companyUpdated}" @click="submit">
      <label>
        <svg-icon icon="paper-plane"></svg-icon>
      </label>
    </li>
  </ul>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Scroll from './Scroll.vue';
import SvgIcon from './SvgIcon.vue';

export default {
  components: {
    Scroll, SvgIcon
  },
  props: {
    actions: {default: false},
    shift: {deault: true},
    blank: { default: "<p><br></p>" },
    value: { default: "" },
  },
  data() {
    return {
      message: '',
    }
  },
  methods: {
    update() {
      if( this.value != this.message ) {
        this.$refs.text.innerHTML = this.value;
        this.message = this.value;
      }
    },
    fixBlankText() {
      if( this.$refs.text.innerHTML == '' ) {
        this.$refs.text.innerHTML = this.blank;
      }
    },
    keyInput(e) {
      if( this.shift ) {
        switch(e.key) {
          case "Enter":
            if( !(e.shiftKey || e.ctrlKey || e.altKey) ) {
              e.preventDefault();
            }
            break;
        }
      }
    },
    textChange(e) {
      if( e.inputType == "insertParagraph" ) {
        e.preventDefault();
      }
      this.fixBlankText();
      let html = this.$refs.text.innerHTML;
      if( html == this.blank ) {
        this.message = '';
      } else {
        this.message = html;
      }
    }
  },
  watch: {
    message() {
      this.$emit('input', this.message);
    },
    value() {
      this.update();
    }
  },
  computed: {
  },
  mounted() {
    document.execCommand('defaultParagraphSeparator', false, 'p');
    this.update();
  },
  beforeDestroy() {
  }
}
</script>