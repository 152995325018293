<template>
  <div class="read" v-if="company">
    <image-loader :src="company.logo.thumb" icon="grid-dark"></image-loader>
    <span v-text="user.name"></span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ImageLoader from '../ImageLoader.vue';

export default {
  props: {
    view: {required: true}
  },
  data: function () {
    return {
    }
  },
  methods: {
  },
  watch: {
  },
  computed: {
    ...mapGetters({
      findUser: 'users/user',
      findCompany: 'clients/client',
    }),
    company() {
      if( this.user ) {
        return this.findCompany(this.user.company_id);
      }
    },
    user() {
      return this.findUser(this.view.user_id);
    },
  },
  mounted() {
    this.$store.dispatch('users/fetch', this.view.user_id);
  },
  beforeDestroy() {
  },

  components: {
    ImageLoader
  },
}
</script>