var focus = {
    namespaced: true,
    state: {
        inFocus: document.hasFocus(),
    },
    mutations: {
        change(state, focus) {
            state.inFocus = focus;
        }
    },
    actions: {
        focus({ commit }) {
            commit('change', true);
        },
        unFocus({ commit }) {
            commit('change', false);
        }
    },
    getters: {
        focus: (state) => (id) => {
            return state.inFocus;
        },
    }
}
 
export default focus;