<template>
  <router-link :class="{updated: updated}"
    tag="li"
    :to="{name: 'adminProject', params: {project_id: project.id}, query: $route.query}">
    <a class="info">
      <span class="project" v-text="project.name"></span>
      <div class="unread" v-if="unreadMessages" v-text="unreadMessages"></div>
      <div class="user" v-if="user" v-text="user.name"></div>
      <div class="body" v-if="message" v-html="message.body"></div>
    </a>
    <a href="#" @click.prevent="archive" class="archive">
      <svg-icon :icon="project.closed ? 'unarchive' : 'archive'"></svg-icon>
    </a>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'
import SvgIcon from '../SvgIcon.vue';

export default {
  components: {
    SvgIcon
  },
  props: {
    project: {required: true}
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      projectLastMessage: 'messages/last',
      projectMessages: 'messages/project',
    }),
    message() {
      return this.projectLastMessage(this.project);
    },
    user() {
      if( this.message ) {
        return this.$store.getters['users/user'](this.message.user_id);
      }
    },
    unreadMessages() {
      if( this.project.unread ) {
        return this.project.unread;
      }
      return 0;
      if( this.message && this.updated ) {
        let messages = this.projectMessages(this.project);
        if( this.viewed_at ) {
          messages = messages.filter(
            ({created_at}) => this.viewed_at.diff(this.$moment(created_at)) < 0
          );
        }
        return messages.length;
      }
      return 0;
    },
    updated() {
      return this.unreadMessages > 0;
    },
    updated_at() {
      return this.$moment(this.project.updated_at);
    },
    viewed_at() {
      if( this.project.viewed_at ) {
        return this.$moment(this.project.viewed_at);
      }
    },
  },
  watch: {
  },
  methods: {
    archive() {
      this.$store.dispatch('projects/archive', this.project);
    }
  },
  mounted() {
  }
}
</script>