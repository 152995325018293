<template>
  <div class="message" :class="classes">
    <div class="photo" v-if="showUser">
      <div class="letter" v-text="user_name[0]"></div>
      <image-loader v-if="company" :src="company.logo.thumb" icon="grid-dark"></image-loader>
    </div>
    <div class="user" v-text="user_name" v-if="showUser"></div>
    <div class="created" v-text="timeS(message.created_at)"></div>
    <div class="actions">
      <a
        class="delete"
        target="_blank"
        @click.prevent="deleteMessage"
        href="#">
        <svg-icon icon="trash"></svg-icon>
      </a>
    </div>
    <div class="body">
      <div class="attachments" v-if="message.attachments.length || uploads.length">
        <a v-for="(upload, i) in uploads" :key="i"
          href="#"
          class="document-attachment"
          @click.prevent>
          <span v-text="upload.progress < 100 ? upload.status : 'A processar'"></span>
          <span>|</span>
          <span v-text="upload.file.name"></span>
          <div class="progress" :style="{width: `${upload.progress}%`}"></div>
        </a>
        <router-link
          :to="{name: 'adminProjectPreview', params: {global_id: attachment.global_id} }"
          class="image-attachment"
          v-for="attachment in images"
          :key="attachment.id">
          <image-loader :src="attachment.url.thumb" icon="grid-dark" alt=""></image-loader>
        </router-link>
        <a
          class="document-attachment"
          v-for="attachment in documents"
          :key="attachment.id"
          target="_blank"
          :href="attachment.url.original">
          <span v-text="attachment.filename"></span>
        </a>
      </div>
      <div class="text" v-html="messageBody"></div>
    </div>
    <div class="read-markers">
      <read-marker :view="view" v-for="view in readViews" :key="view.id"></read-marker>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ImageLoader from '../ImageLoader.vue';
import SvgIcon from '../SvgIcon.vue';
import ReadMarker from './ReadMarker.vue';

export default {
  props: {
    index: { required :true },
    messages: { required :true },
    message: { required :true },
    views: { required :true },
    showUser: {default: true},
  },
  data() {
    return {
    }
  },
  methods: {
    deleteMessage() {
      this.$store.dispatch('messages/delete', this.message);
    },
    timeS(time) {
      let theTime = this.$moment(time);
      let now = this.$moment();
      let minutesApart =  now.diff(theTime, 'minutes');
      if(minutesApart < 10) {
        return this.$moment(time).fromNow();
      }
      if(theTime.year() != now.year()) {
        return theTime.format("HH:mm DD/MM/YYYY");
      }
      if(theTime.month() != now.month()) {
        return theTime.format("HH:mm DD/MM");
      }
      if(theTime.date() != now.date()) {
        return theTime.format("HH:mm DD");
      }
      return theTime.format("HH:mm");
    },
  },
  watch: {
  },
  computed: {
    ...mapGetters({
      projectMessages: 'messages/project',
      messageUploads: 'messages/uploads',
    }),
    project() {
      return this.findProject(this.message.project_id);
    },
    uploads() {
      return this.messageUploads(this.message);
    },
    readViews() {
      let message = this.message;
      let nextIndex = this.index + 1;
      let messageTime = this.$moment(message.created_at);
      let views = this.views.filter( ({updated_at}) => {
        let viewed_at = this.$moment(updated_at);
        return viewed_at.isSameOrAfter(messageTime);
      });
      if( nextIndex < this.messages.length ) {
        let nextTime = this.$moment(this.messages[nextIndex].created_at);
        views = views.filter( ({updated_at}) => {
          let viewed_at = this.$moment(updated_at);
          return viewed_at.isBefore(nextTime);
        });
      }
      return views;
    },
    documents() {
      return this.message.attachments.filter(
        ({content_type}) => content_type.match(/image/gi) == null || content_type.match(/svg/gi) || content_type.match(/adobe/gi)
      )
    },
    images() {
      return this.message.attachments.filter(
        ({content_type}) => content_type.match(/image/gi) && content_type.match(/svg/gi) == null && content_type.match(/adobe/gi) == null
      )
    },
    classes() {
      return {
        blat: this.company && this.company.name == 'BlatStudio',
        online: this.online,
      };
    },
    company() {
      if( this.user ) {
        return this.$store.getters['clients/client'](this.user.company_id);
      }
    },
    messageBody() {
      if( this.message ) {
        return this.message.body.replaceAll('\n', "<br>")
      }
      return ''
    },
    online() {
      let onlineUsers = this.$store.getters['users/online'];
      return onlineUsers.includes(this.message.user_id);
    },
    user() { return this.$store.getters['users/user'](this.message.user_id) },
    user_name() {
      if( this.user ) {
        return this.user.name;
      }
      return '...';
    },
  },
  mounted() {
    this.$store.dispatch('users/fetch', this.message.user_id);
  },
  beforeDestroy() {
  },

  components: {
    ImageLoader, ReadMarker, SvgIcon
  },
}
</script>