<template>
  <div
    @dragleave="dragleaving($event)"
    @dragenter="dragging($event)"
    @dragover.prevent
    @drop="dragged($event)">
    <h3 class="title">
      <span v-text="this.title"></span>
      <span v-text="total"></span>
    </h3>
    <div class="drag" ref="dragEl">
      <span v-text="dragString"></span>
    </div>
    <table>
      <thead>
        <tr :class="{selected: selected.length === invoices.length, partial: selected.length > 0}">
          <td class="icon" @click="selectAll">
            <span class="square">
              <svg-icon icon="tick"></svg-icon>
              <svg-icon icon="remove-line" class="remove"></svg-icon>
            </span>
          </td>
          <td class="icon">
            <svg-icon icon="archive"></svg-icon>
          </td>
          <td class="icon">
            <svg-icon icon="trash"></svg-icon>
          </td>
          <td class="attach" v-if="selected.length">
            <span v-text="selectedTotal"></span>
          </td>
          <template v-if="attachedFile">
            <td class="input">
              <input type="text" v-model="billDescription">
            </td>
            <td class="input value">
              <input type="number" step="0.01" min="0" v-model="value">
            </td>
            <td class="input filename">
              <a v-text="filename" :title="filename"></a>
            </td>
            <td class="icon attach" @click="upload">
              <svg-icon icon="paper-plane"></svg-icon>
            </td>
            <td class="icon attach" @click="attachedFile = null">
              <svg-icon icon="cancel"></svg-icon>
            </td>
          </template>
          <template v-else>
            <td class="input">
            </td>
            <td class="icon attach" @click="attachFile">
              <svg-icon icon="attach"></svg-icon>
              <input type="file" ref="file" @change="handleFiles">
            </td>
          </template>
        </tr>
      </thead>
    </table>
    <div class="list">
      <scroll>
        <table>
          <tbody>
            <tr :key="invoice.id" v-for="invoice in invoices"
              :class="{selected: invoiceSelected(invoice)}"
              draggable="true"
              @dragstart="drag($event, invoice)"
              @drop="dragged($event)">
              <td class="icon" @click="selectInvoice(invoice)">
                <span class="square">
                  <svg-icon icon="tick"></svg-icon>
                </span>
              </td>
              <td v-text="invoice.description"></td>
              <td v-text="`${parseFloat(invoice.value || 0).toFixed(2)} €`"></td>
              <td class='link'>
                <a v-text="invoice.filename"
                  :href="invoice.bill"
                  target="_blank"
                  draggable="false"
                  :title="invoice.filename">
                </a>
              </td>
              <td class="icon send">
                <router-link :to="{name: 'send', params: {invoice_id: invoice.id}}">
                  <svg-icon icon="paper-plane"></svg-icon>
                </router-link>
              </td>
              <!-- <td class="icon edit">
                <svg-icon icon="pencil"></svg-icon>
              </td> -->
              <td class="icon archive" @click="$emit('update', {invoice: invoice, params: {paid: !invoice.paid}})">
                <svg-icon :icon="invoice.paid ? 'unarchive' : 'archive'"></svg-icon>
              </td>
              <td class="icon delete" @click="$emit('destroy', invoice)">
                <svg-icon icon="trash"></svg-icon>
              </td>
            </tr>
          </tbody>
        </table>
      </scroll>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Scroll from '../Scroll.vue';
import ImageLoader from '../ImageLoader.vue';
import SvgIcon from '../SvgIcon.vue';

export default {
  components: {
    Scroll, ImageLoader, SvgIcon
  },
  props: {
    title: {required: true},
    invoices: {required: true},
    client_id: {required: true},
    paid: {required: true},
  },
  data() {
    return {
      selected: [],
      draggingStage: 0,
      attachedFile: null,
      billDescription: '',
      progress: 0,
      value: '',
    }
  },
  computed: {
    ...mapGetters({
      clientById: 'clients/client',
    }),
    client() {
      return this.clientById(this.client_id);
    },
    dragString() {
      return `A arrastar ${this.selected.length}`;
    },
    filename() {
      if( this.attachedFile ) {
        return this.attachedFile.name;
      }
    },
    selectedTotal() {
      let total = this.selected.reduce((prev, curr) => prev + parseFloat(curr.value || 0), parseFloat(0));
      return `${total.toFixed(2)} €`;
    },
    total() {
      let total = this.invoices.reduce((prev, curr) => prev + parseFloat(curr.value || 0), parseFloat(0));
      return `${total.toFixed(2)} €`;
    },
  },
  methods: {
    attachFile() {
      this.$refs.file.click();
    },
    handleFiles() {
      let files = this.$refs.file.files;
      this.attachedFile = files[0];
    },
    drag($event, invoice) {
      this.selectInvoice(invoice, true);
      $event.dataTransfer.setData('invoices', JSON.stringify(this.selected));
      $event.dataTransfer.setDragImage(this.$refs.dragEl, 0, 0);
    },
    dragged($event) {
      let invoices = $event.dataTransfer.getData('invoices');
      if( invoices != '' ) {
        invoices = JSON.parse(invoices);
        this.draggingStage = 0;
        for (let i = 0; i < invoices.length; i++) {
          let invoice = invoices[i];
          if( invoice.paid != this.paid ) {
            this.$emit('update', {invoice: invoice, params: {paid: this.paid}});
          }
        }
      }
    },
    dragleaving($event) {
      let invoices = $event.dataTransfer.getData('invoices');
      if( invoices != "" ) {
        this.draggingStage--;
      }
    },
    dragging($event) {
      let invoices = $event.dataTransfer.getData('invoices');
      if( invoices != "" ) {
        this.draggingStage++;
      }
    },
    invoiceSelected(invoice) {
      return this.selected.includes(invoice);
    },
    selectInvoice(invoice, force = false) {
      let index = this.selected.findIndex( ({id}) => id == invoice.id );
      if( index > -1) {
          this.selected.splice(index, 1);
      }
      if( force || (index < 0) ) {
        this.selected.push(invoice);
      }
    },
    selectAll() {
      if( this.selected.length > 0 ) {
        this.selected = [];
        return;
      }
      for (let i = 0; i < this.invoices.length; i++) {
        const invoice = this.invoices[i];
        this.selectInvoice(invoice, true);
      }
    },
    upload() {
      let formData = new FormData();
      formData.append('invoice[attachment]', this.attachedFile);
      formData.append('invoice[description]', this.billDescription);
      formData.append('invoice[value]', this.value);
      formData.append('invoice[paid]', this.paid);
      axios.post(`/api/companies/${this.client_id}/invoices/`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress(e) {
              this.progress = Math.round( (e.loaded * 100) / e.total );
          }
      } ).then( response => {
        this.billDescription = '';
        this.attachedFile = null;
        this.invoices.unshift(response.data);
      }).catch((e) => {
          console.log(e);
      });
    }
  },
  watch: {
    invoices() {
      this.selected = this.selected.filter( ({id}) => this.invoices.findIndex(inv => inv.id == id) > -1);
    },
  },
  mounted() {
  },
  beforeDestroy() {
  },
}
</script>