<template>
  <div class="blat-scrollable" :style="style" @scroll="$emit('scroll', $event)">
    <slot></slot>
    <div class="blat-scrolltrack" :class="{active: scrolling, scrolling: isScrolling}">
      <div class="blat-scrollbar"
        @mousedown="initScroll"
        :style="{ top: scrollbarTop + 'px', height: scrollbarHeight + 'px' }">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data: function () {
    return {
      scrollHeight: 0,
      scrollTop: 0,
      scrollbarHeight: 0,
      scrollbarTop: 0,
      scrolltrackHeight: 0,
      position: 0,
      hiddenMargin: 0,
      mouseStart: null,
      isScrolling: false,
      scrollTimeout: null,
    }
  },
  methods: {
    disableSelect($event) {
      $event.preventDefault();
    },
    initScroll($event) {
      this.mouseStart = { mouse: $event.y, barTop: this.scrollbarTop}
      document.addEventListener('mouseup', this.endScroll, false);
      document.addEventListener('mousemove', this.moveScroll, false);
      document.addEventListener('selectstart', this.disableSelect, false);
    },
    moveScroll($event) {
      let position = this.mouseStart.barTop + ($event.y - this.mouseStart.mouse);
      let maxTop = this.scrolltrackHeight - this.scrollbarHeight;
      let scrollbarTop = Math.max(0, Math.min(position, maxTop));
      let scrollTop = (scrollbarTop*this.scrollHeight)/this.scrolltrackHeight;
      this.$el.scrollTop = scrollTop;
    },
    endScroll() {
      this.mouseStart = null;
      document.removeEventListener('mousemove', this.moveScroll, false)
      document.removeEventListener('mouseup', this.endScroll, false);
      document.removeEventListener('selectstart', this.disableSelect, false);
    },
    checkScrollPosition() {
      this.scrollHeight = this.$el.scrollHeight;
      this.scrollTop = this.$el.scrollTop;
      this.scrolltrackHeight = this.$scrolltrack.clientHeight;
      this.scrollbarHeight = Math.ceil((this.scrolltrackHeight*this.scrolltrackHeight)/this.scrollHeight);
      this.scrollbarTop = (this.scrollTop*this.scrolltrackHeight)/this.scrollHeight;
    },
    scrollListener() {
      clearTimeout(this.scrollTimeout);
      this.isScrolling = true;
      this.checkScrollPosition();
      this.scrollTimeout = setTimeout( () => {
        this.isScrolling = false;
      }, 1000);
    },
  },
  computed: {
    style() {
      if( this.hiddenMargin != 0 ) {
        return { 'margin-right': `${this.hiddenMargin}px` };
      }
    },
    $scrolltrack() {
      return this.$el.querySelector('.blat-scrolltrack');
    },
    scrolling() {
      return this.mouseStart != null;
    }
  },
  mounted() {
    this.$el.addEventListener('scroll', this.scrollListener, false);
    let style = this.$el.currentStyle || window.getComputedStyle(this.$el);
    let margin = parseInt(style.marginRight);
    this.hiddenMargin = margin - (this.$el.offsetWidth - this.$el.clientWidth);
    this.checkScrollPosition();
  },
  beforeDestroy() {
    this.$el.removeEventListener('scroll', this.scrollListener, false);
  }
}
</script>