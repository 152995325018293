<template>
  <div v-if="client" class="contacts-show">
    <form action="/" @submit.prevent="submit">
      <label for="name">Nome</label>
      <input type="text" id="name">

      <label for="email">Email</label>
      <input type="text" id="email">

      <button type="submit">Gardar</button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SvgIcon from '../SvgIcon.vue';

export default {
  components: {
  },
  props: {
    client_id: {required: true},
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      clientById: 'clients/client',
    }),
    client() {
      return this.clientById(this.client_id);
    },
  },
  methods: {
    submit() {
      console.log('should submit');
    },
  },
  watch: {
  },
  mounted() {
  },
  beforeDestroy() {
  },
}
</script>