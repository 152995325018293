import AdminProjectManagement from '../components/adminProjectManagement.vue';
import AdminClients from '../components/adminClients.vue';
import AdminClientProjects from '../components/admin/ClientProjects';
import ContactsNew from '../components/contacts/New';
import ContactsSend from '../components/contacts/Send';
import ClientsShow from '../components/clients/Show';
import Mails from '../components/mails/Index';
import ProjectMessages from '../components/messages/Messages';
import ProjectFiles from '../components/projects/Files';
import ProjectInfo from '../components/projects/Info';
import Preview from '../components/projects/Preview';

const routes = [
  {
    path: '/emails',
    component: Mails,
    name: 'emails',
  },
  {
    path: '/empresas',
    component: AdminClients,
    name: 'companies',
    children: [
      {
        path: ':client_id',
        name: 'adminClient',
        props: {client: true},
        components: {
          client: ClientsShow
        },
        children: [{
          path: 'new_contact',
          name: 'newClientContact',
          props: {default: true},
          components: {
            default: ContactsNew
          },
        },{
          path: 'send/:invoice_id',
          name: 'send',
          props: {default: true},
          components: {
            default: ContactsSend
          },
        }]
      },
    ]
  },
  {
    path: '/admin',
    component: AdminProjectManagement,
    name: 'admin',
    children: [
      {
        path: ':client_id',
        name: 'adminClientProjects',
        props: {projects: true},
        components: {
          projects: AdminClientProjects
        },
      },
      {
        path: ':client_id/:project_id',
        name: 'adminProject',
        props: {projects: true, messages: true, files: true, information: true},
        components: {
          projects: AdminClientProjects,
          messages: ProjectMessages,
          files: ProjectFiles,
          information: ProjectInfo,
        },
      },
      {
        path: ':client_id/:project_id/preview/:global_id',
        name: 'adminProjectPreview',
        props: {projects: true, messages: true, files: true, information: true, preview: true},
        components: {
          projects: AdminClientProjects,
          messages: ProjectMessages,
          files: ProjectFiles,
          information: ProjectInfo,
          preview: Preview,
        },
      }
    ]
  },
]

export default routes;
