<template>
  <div id="new-message" :class="{has_text: message != ''}">
    <ul class="uploads" v-if="uploads.length > 0">
      <li v-for="upload in uploads" :key="upload.id" :class="{uploaded: upload.uploaded}">
        <span v-text="upload.file.name"></span>
        <span v-text="$root.readableBytes(upload.file.size)"></span>
        <a href="#" @click.prevent="removeUploaded(upload)" class="remove">
          <svg-icon icon="cancel"></svg-icon>
        </a>
      </li>
    </ul>
    <div class="editor">
      <div class="placeholder">
        Escreva aqui a sua mensagem
      </div>
      <scroll class="input">
        <div class="text" @keydown="keyInput" @input="textChange" contenteditable ref="text">
          <p><br></p>
        </div>
      </scroll>
      <ul class="actions">
        <li class="notification" :class="{closed: companyUpdated, secondary: project.company_updated}" @click="notify">
          <label>
            <svg-icon icon="horn"></svg-icon>
          </label>
        </li>
        <li class="attachment">
          <input type="file" id="files" ref="file" multiple @change="handleFiles"/>
          <label for="files">
            <svg-icon icon="attach"></svg-icon>
          </label>
        </li>
        <li class="send" :class="{closed: companyUpdated}" @click="submit">
          <label>
            <svg-icon icon="paper-plane"></svg-icon>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Scroll from '../Scroll.vue';
import SvgIcon from '../SvgIcon.vue';
import Icons from 'images/icons.svg';

export default {
  components: {
    Scroll, SvgIcon
  },
  props: {
    icons: {default: Icons},
    project: { required: true},
    blank: {default: "<p><br></p>"},
  },
  data() {
    return {
      message: '',
      uploads: [],
    }
  },
  methods: {
    addFile(file) {
      let uploadId = file.name + file.size;
      let index = this.uploads.findIndex( (upload) => upload.file === file || uploadId === upload.id);
      if( index > -1 ) {
        this.uploads.splice(index, 1);
      }
      let upload = {
        id: uploadId,
        file: file,
        uploaded: false,
        error: false
      }
      this.uploads.push(upload);
    },
    notify() {
      this.$store.dispatch('projects/notify', this.project);
    },
    submit() {
      if(this.message != '' || this.uploads.length > 0 ) {
        this.$refs.text.innerHTML = this.blank;
        let body = '';
        if( this.message == '' ) {
          body = this.uploads.reduce((t, cv, ci, arr) => {
            if ( t == '' ) {
              return cv.file.name;
            }
            return `${t} | ${cv.file.name}`;
          }, '')
        } else {
          body = this.message;
        }
        this.message = '';
        this.$store.dispatch('messages/create', {
          project: this.project,
          message: body,
          uploads: this.uploads,
        })
        this.uploads = [];
      }
    },
    fixBlankText(element) {
      if( element.innerHTML == '' ) {
        element.innerHTML = this.blank;
      }
    },
    handleFiles() {
      let files = this.$refs.file.files;
      for( let i = 0; i < files.length; i++ ) {
        this.addFile(files[i]);
      }
    },
    keyInput(e) {
      switch(e.key) {
        case "Enter":
          if( !(e.shiftKey || e.ctrlKey || e.altKey) ) {
            e.preventDefault();
            this.submit();
          }
          break;
      }
    },
    removeUploaded(upload = null) {
      if(upload == null) {
        this.uploads = this.uploads.filter( (upload) => !upload.uploaded );
      } else {
        let index = this.uploads.findIndex( ({id}) => id == upload.id );
        if( index > -1 ) {
          this.uploads.splice(index, 1);
        }
      }
    },
    textChange(e) {
      if( e.inputType == "insertParagraph" ) {
        e.preventDefault();
      } else {
      }
      this.fixBlankText(e.target);
      let html = e.target.innerHTML;
      if( html == this.blank ) {
        this.message = '';
      } else {
        this.message = html;
      }
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters({
      projectViews: 'projects/projectViews',
      findUsers: 'users/users',
    }),
    views() {
      return this.projectViews(this.project.id);
    },
    users() {
      return this.findUsers(this.views.reduce((t, cv, ci, arr) => {
        t.push(cv.user_id);
        return t;
      }, []));
    },
    companyUpdated() {
      let updated_at = this.$moment(this.project.updated_at);
      if( this.views.length == 0 ) {
        return true;
      }
      for (let i = 0; i < this.users.length; i++) {
        let view = this.views[i];
        let user = this.users.find(({id}) => id === view.user_id);
        if( user == null ) {
          this.$store.dispatch('users/fetch', view.user_id);
          return true;
        }
        if( user.company_id == this.project.company_id ) {
          let viewed_at = this.$moment(view.updated_at);
          if( updated_at.isSameOrBefore(viewed_at) ) {
            return true;
          }
        }
      }
      return false;
    }
  },
  mounted() {
    document.execCommand('defaultParagraphSeparator', false, 'p');
  },
  beforeDestroy() {
  }
}
</script>