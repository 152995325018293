<template>
<div class="mails-index">
  <scroll>
    <table>
      <tbody>
        <tr v-for="mail in mails" :key="mail.id">
          <td class="" v-text="mail.email">
          </td>
          <td class="" v-text="mail.subject">
          </td>
          <td class="" v-text="mail.status">
          </td>
          <td class="" v-text="mail.message_id">
          </td>
        </tr>
      </tbody>
    </table>
  </scroll>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import SvgIcon from '../SvgIcon.vue';
import Scroll from '../Scroll.vue';

export default {
  components: {
    SvgIcon, Scroll
  },
  props: {
  },
  data() {
    return {
      mails: [],
    }
  },
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
  },
  watch: {
  },
  mounted() {
    // this.$store.dispatch('contacts/fetch', this.client_id);
    axios.get('/api/mail_messages')
    .then( ({data}) => this.mails = data );
  },
  beforeDestroy() {
  },
}
</script>