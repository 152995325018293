<template>
  <div id="project-preview" v-if="project" @click="goToProject($event.target)">
    <template v-if="image">
      <image-loader :src="image.url.medium" :key="image.url.original"></image-loader>
    </template>
    <div class="actions">
      <h3 v-if="image">
        <span v-text="image.filename"></span>
        (<span v-text="filesize"></span>)
      </h3>
      <a class="action" :href="image.url.original" v-if="image" target="_blank" @click.prevent="download(image)">
        <svg-icon icon="download"></svg-icon>
      </a>
      <router-link class="close action" :to="{name: 'adminProject', params: {project_id: project.id}}">
        <svg-icon icon="cancel"></svg-icon>
      </router-link>
    </div>
    <div class="images">
      <router-link v-for="image in images" :key="image.global_id" :to="{name: 'adminProjectPreview', params: {global_id: image.global_id}}">
        <image-loader :src="image.url.thumb"></image-loader>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Scroll from '../Scroll.vue';
import ImageLoader from '../ImageLoader.vue';
import SvgIcon from '../SvgIcon.vue';
import axios from 'axios';
import { getDownloadURL } from 'firebase/storage';

export default {
  components: {
    Scroll, ImageLoader, SvgIcon
  },
  props: {
    project_id: {required: true},
    global_id: {required: true},
  },
  data: function () {
    return {
    }
  },
  methods: {
      async download(image) {
          let url = image.url.original;
          if( image.meta != null ) {
              url = await getDownloadURL(image.refs.original)
          }
          console.log(url)
          axios({
              method: 'get',
              url: url,
              responseType: 'blob',
          }).then(function(response) {
              let blob = response.data;
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = image.filename;
              link.target = "_self";
              link.click();
          })
      },
    goToProject(target) {
      if(this.closers.includes(target)) {
        this.$router.push(
          {
            name: 'adminProject',
            params: {
              client_id: this.project.company_id,
              project_id: this.project.id
            }
          }
        )
      }
    },
  },
  watch: {
  },
  computed: {
    ...mapGetters({ clientProject: 'projects/project' }),
    project() { return this.clientProject(this.project_id) },
    image() { return this.images.find(({global_id}) => global_id == this.global_id)},
    closers() {
      return [
        this.$el
      ]
    },
    filesize() {
      if( this.image ) {
        return `${this.$root.readableBytes(this.image.byte_size)}`;
      }
      return '.';
    },
    images() {
      return this.$parent.$refs.files.images;
    }
  },
  mounted() {
  },
  beforeDestroy() {
  }
}
</script>
