<template>
  <div id="project-messages" :class="classes"
    @dragenter="dragEnter"
    @dragleave="dragLeave"
    @drop.prevent="drop"
    @dragover.prevent>
    <h3 class="title" v-if="project">
      <svg-icon icon="locked-padlock" v-if="project.closed"></svg-icon>
      <span v-text="project.name"></span>
    </h3>
    <div class="chat">
      <scroll @scroll="scrollListener" ref="scroll">
        <div class="loading" v-text="loadingText"></div>
        <template v-for="(message, index) in messages">
          <message
            :showUser="index == 0 || messages[index].user_id != messages[index-1].user_id"
            :key="message.id"
            :messages="messages"
            :index="index"
            :views="views"
            :message="message">
          </message>
        </template>
      </scroll>
    </div>
    <NewMessage :project="project"
      ref="newMessage"
      v-if="project && !project.closed">
    </NewMessage>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Scroll from '../Scroll.vue';
import NewMessage from './New.vue';
import Message from './Message.vue';
import SvgIcon from '../SvgIcon.vue';
import ImageLoader from '../ImageLoader.vue';

export default {
  components: {
    Scroll, NewMessage, Message, SvgIcon, ImageLoader
  },
  props: {
    project_id: {required: true}
  },
  data: function () {
    return {
      channelSubscription: null,
      fetchingMessages: false,
      dragging: 0,
    }
  },
  methods: {
    drop(e) {
      this.dragging = 0;
      let files = e.dataTransfer.files;
      for( let i in files ) {
        let file = files[i];
        if(file instanceof File) {
          this.$refs.newMessage.addFile(file);
        }
      }
    },
    dragEnter(e) {
      this.dragging++;
    },
    dragLeave(e) {
      this.dragging--;
    },
    updateProjectView() {
      console.log('trying updating view')
      if( this.messages.length > 0 ) {
        console.log('updating viewed at')
        this.$store.dispatch('projects/viewed', this.project);
      } else {
        setTimeout(this.updateProjectView, 200);
      }
    },
    updateMessage(message) {
      this.$store.commit('messages/add', message)
    },
    subscribe() {
      this.$store.dispatch('projects/fetchViews', {id: this.project_id});
      if( this.channelSubscription != null ) {
        this.$root.cable.subscriptions.remove(this.channelSubscription);
      }
      let vueMessages = this;
      this.channelSubscription = this.$root.cable.subscriptions.create({
        channel: "MessagesChannel",
        project_id: this.project_id,
      },{
        // initialized() {
        //   console.log(`Message Channel Initialized on Project:${vueMessages.project_id}`);
        //   console.log(this);
        // },
        // connected() {
        //   console.log("Message Channel Connected");
        // },
        // disconnected() {
        //   console.log("Message Channel Disconnected");
        // },
        // rejected() {
        //   console.log("Message Channel Rejected");
        // },
        received(data) {
          switch (data.type) {
            case "message":
              vueMessages.updateMessage(data.content);
              break;
            case "view":
              vueMessages.$store.commit('projects/addViews', [data.content]);
              break;
            default:
              console.log(data);
              break;
          }
        }
      })
    },
    timeS(time) {
      let theTime = this.$moment(time);
      let now = this.$moment();
      let minutesApart =  now.diff(theTime, 'minutes');
      if(minutesApart < 10) {
        return this.$moment(time).fromNow();
      }
      if(theTime.year() != now.year()) {
        return theTime.format("kk:mm DD/MM/YYYY");
      }
      if(theTime.month() != now.month()) {
        return theTime.format("kk:mm DD/MM");
      }
      if(theTime.date() != now.date()) {
        return theTime.format("kk:mm DD");
      }
      return theTime.format("kk:mm");
    },
    scrollListener() {
      if(this.onTop()) {
        this.fetchMessages();
      }
    },
    clientHeight() { return this.$refs.scroll.$el.clientHeight }, // element height
    scrollHeight() { return this.$refs.scroll.$el.scrollHeight }, // amount of scrollable height
    scrollTop() { return this.$refs.scroll.$el.scrollTop }, // scrolled from top
    onBottom() {
      let top = this.scrollTop();
      let height = this.scrollHeight();
      let client = this.clientHeight();
      return height == client || top + client > height - 5;
    },
    onTop() {
      return this.scrollTop() < 10;
    },
    fetchMessages() {
      if( this.moreMessages && this.project ) {
        this.$store.dispatch('messages/fetchMore', {project: this.project});
      } else {
        setTimeout(this.fetchMessages, 200);
      }
    }
  },
  watch: {
    messages() {
      if(this.onBottom()) {
        this.$nextTick()
        .then( () => this.$refs.scroll.$el.scrollTop = this.scrollHeight() );
      } else {
        let bottomDistance = this.scrollHeight() - this.scrollTop();
        this.$nextTick()
        .then( () => this.$refs.scroll.$el.scrollTop = this.scrollHeight() - bottomDistance);
      }
      this.$nextTick()
      .then( () => this.scrollListener() );
    },
    project_id() {
      this.$refs.scroll.$el.scrollTop = this.scrollHeight();
      this.subscribe();
      this.fetchMessages();
      this.updateProjectView();
    },
  },
  computed: {
    ...mapGetters({
      projectMessages: 'messages/project',
      fetchedAllMessages: 'messages/complete',
      clientProject: 'projects/project',
      projectViews: 'projects/projectViews',
    }),
    classes() {
      return {
        closed: (this.project && this.project.closed),
        dragging: this.dragging,
      }
    },
    loadingText() { return this.moreMessages ? 'A carregar' : 'Não existem mais mensagens'},
    project() { return this.clientProject(this.project_id) },
    messages() {
      if(this.project) {
        return this.projectMessages(this.project).sort( (a,b) => a.id - b.id );
      }
      return [];
    },
    moreMessages() {
      if( this.project ) {
        return !this.fetchedAllMessages(this.project);
      }
      return true;
    },
    views() {
      return this.projectViews(this.project_id);
    },
  },
  mounted() {
    this.fetchMessages();
    this.subscribe();
    this.updateProjectView();
    this.$refs.scroll.$el.scrollTop = this.scrollHeight();
  },
  beforeDestroy() {
    this.$root.cable.subscriptions.remove(this.channelSubscription);
  }
}
</script>