var clients = {
    namespaced: true,
    state: {
        all: [],
    },
    mutations: {
        all(state, companies) {
            state.all = companies;
        },
        addMultiple(state, contacts) {
            contacts.forEach( contact => {
                let index = state.all.findIndex( ({id}) => id == contact.id );
                if( index > -1 ) {
                    state.all.splice(index, 1);
                }
                state.all.push(contact);
            });
        },
        add(state, contact) {
            let index = state.all.findIndex( ({id}) => id == contact.id );
            if( index > -1 ) {
                state.all.splice(index, 1);
            }
            state.all.push(contact);
        },
        remove(state, contact) {
            state.all.splice(state.all.findIndex(({id}) => contact.id == id), 1);
        },
    },
    actions: {
        create({commit}, {id, params}) {
            axios.post(`/api/companies/${id}/contacts`, params)
            .then( ({data}) => commit('add', data) )
            .catch( (error) => console.log(error) );
        },
        destroy({commit}, contact) {
            axios.delete(`/api/companies/${contact.company_id}/contacts/${contact.id}`)
            .then( ({data}) => commit('remove', contact) )
            .catch( (error) => console.log(error) );
        },
        fetch({ commit, state }, client_id) {
            axios.get(`/api/companies/${client_id}/contacts`)
            .then( ({data}) => commit('addMultiple', data))
            .catch( (error) => console.log(error) );
        },
        send({state}, {subject, contacts, invoice_id, content}) {
            axios.post('/api/mail_messages', {
                contacts,
                invoice_id,
                mail_message: {
                    subject,
                    content,
                },
            });
            // axios.post(`/api/companies/${client_id}/invoices/${invoice_id}/send/`, {contacts, content});
            // .then( ({data}) => commit('add', data) )
            // .catch( (error) => console.log(error) );
        },
    },
    getters: {
        client: (state) => (client_id) => {
            return state.all.filter( ({company_id}) => company_id == client_id );
        },
    }
}
 
export default clients;