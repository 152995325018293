var users = {
    namespaced: true,
    state: {
        all: [],
        online: [],
    },
    mutations: {
        online(state, ids) {
            state.online = ids;
        },
        one(state, user) {
            let index = state.all.findIndex( ({id}) => id == user.id );
            if( index > -1 ) {
                state.all.splice(index, 1);
            }
            state.all.push(user);
        },
        remove(state, user_id) {
            let index = state.all.findIndex( ({id}) => id == user_id );
            if( index > -1 ) {
                state.all.splice(index, 1);
            }
        }
    },
    actions: {
        fetch({commit, state}, user_id) {
            let index = state.all.findIndex( ({id}) => id == user_id );
            if( index < 0 ) {
                commit('one', {id: user_id, name: '...'});
                axios.get(`/api/users/${user_id}`)
                .then( response => commit('one', response.data))
                .catch( response => commit('remove', user_id));
            }
        }
    },
    getters: {
        user: (state) => (user_id) => {
            return state.all.find( ({id}) => user_id == id );
        },
        users: (state) => (ids) => {
            return state.all.filter( ({id}) => ids.includes(id) );
        },
        online: (state) => {
            return state.online;
        },
    }
}
 
export default users;