import htmx from "htmx.org"
import { getStorage, ref, listAll, getDownloadURL, getMetadata } from "firebase/storage";
import {app} from "./fb"
import Dropzone from "../../../../vendor/assets/javascripts/dropzone"

const storage = getStorage(app);

async function getFiles(filesRef) {
    let res = await listAll(filesRef)
    return res.prefixes
}

let reg = /\/projects\/\d+/
let projectPath = reg.exec(window.location.pathname)
let projectId = null
if( projectPath != null ) {
    projectId = parseInt(projectPath[0].replace("/projects/",""))
}

(async () => {
    if( projectId == null ) {
        return
    }

    // Create a reference under which you want to list
    const imagesRef = ref(storage, `projects/${projectId}/images`);
    const filesRef = ref(storage, `projects/${projectId}/files`);
    const finalRef = ref(storage, `projects/${projectId}/final`);

    const imageRefs = await getFiles(imagesRef)
    const filesRefs = await getFiles(filesRef)
    const finalRefs = await getFiles(finalRef)
    var regExp = /\(([^)]+)\)/

    const thumbsPanel = htmx.find("#images_panel .clearing-thumbs")
    if(thumbsPanel != null) {
        let images = []
        for( let folder of imageRefs ) {
            let res = await listAll(folder)
            let data = {}
            for( let f of res.items ) {
                if( f.name == "800x600" ) {
                    data.medium = await getDownloadURL(f)
                } else if( f.name == "100x100" ) {
                    data.thumb = await getDownloadURL(f)
                } else {
                    data.original = await getDownloadURL(f)
                    let meta = await getMetadata(f)
                    data.createdAt = (new Date(meta.timeCreated)).getTime()
                }
            }
            if( data.original == null )
                continue
            images.push(data)
        }
        images = images.sort((a,b) => a.createdAt - b.createdAt)
        for(let image of images) {
            let url = image.thumb
            let li = document.createElement('li')
            thumbsPanel.appendChild(li)
            let a = document.createElement('a')
            let mediumUrl = image.medium != null ? image.medium : image.original
            a.setAttribute('href', mediumUrl)
            li.appendChild(a)
            let img = document.createElement('img')
            img.setAttribute('src', url)
            a.appendChild(img)
        }

        const imagesButton = htmx.find(htmx.find("#images_panel").parentElement, "a")
        var imagesButtonTextMatches = regExp.exec(imagesButton.innerText)
        let nrImages = images.length
        if( imagesButtonTextMatches == null && nrImages > 0 ) {
            imagesButton.innerText = imagesButton.innerText + ` (${nrImages})`
        } else if( imagesButtonTextMatches != null ) {
            nrImages += parseInt(imagesButtonTextMatches[1])
            imagesButton.innerText = imagesButton.innerText.replace(/\((.+?)\)/g, "("+nrImages+")")
        }
        const deleteImage = htmx.find("#images_panel .remove")
        deleteImage.remove()
    }

    const otherFiles = htmx.find("#others_panel .button-group")
    if(otherFiles != null) {
        let files = []
        for( let folder of filesRefs ) {
            let res = await listAll(folder)
            let data = {}
            for( let f of res.items ) {
                data.original = await getDownloadURL(f)
                let meta = await getMetadata(f)
                data.createdAt = (new Date(meta.timeCreated)).getTime()
                data.name = f.name
            }
            files.push(data)
        }
        files = files.sort((a,b) => a.createdAt - b.createdAt)
        for(let file of files) {
            let url = file.original
            let li = document.createElement('li')
            otherFiles.appendChild(li)
            let a = document.createElement('a')
            a.setAttribute('href', url)
            a.classList.add('button')
            a.classList.add('info')
            a.innerText = file.name
            li.appendChild(a)
        }

        const othersButton = htmx.find(htmx.find("#others_panel").parentElement, "a")
        var othersButtonTextMatches = regExp.exec(othersButton.innerText)
        let nrImages = files.length
        if(othersButtonTextMatches == null && nrImages > 0) {
            othersButton.innerText = othersButton.innerText + ` (${nrImages})`
        } else if( othersButtonTextMatches != null ) {
            nrImages += parseInt(othersButtonTextMatches[1])
            othersButton.innerText = othersButton.innerText.replace(/\((.+?)\)/g, "("+nrImages+")")
        }
    }

    const finalFiles = htmx.find("#final_art .button-group")
    if(finalFiles != null) {
        let files = []
        for( let folder of finalRefs ) {
            let res = await listAll(folder)
            let data = {}
            for( let f of res.items ) {
                data.original = await getDownloadURL(f)
                let meta = await getMetadata(f)
                data.createdAt = (new Date(meta.timeCreated)).getTime()
                data.name = f.name
            }
            files.push(data)
        }
        files = files.sort((a,b) => a.createdAt - b.createdAt)
        for(let file of files) {
            let url = file.original
            let li = document.createElement('li')
            finalFiles.appendChild(li)
            let a = document.createElement('a')
            a.setAttribute('href', url)
            a.classList.add('button')
            a.classList.add('info')
            a.innerText = file.name
            li.appendChild(a)
        }
        const finalButton = htmx.find(htmx.find("#final_art").parentElement, "a")
        var finalButtonTextMatches = regExp.exec(finalButton.innerText)
        let nrImages = files.length
        if( finalButtonTextMatches == null && nrImages > 0 ) {
            finalButton.innerText = finalButton.innerText + ` (${nrImages})`
        } else if( finalButtonTextMatches != null ) {
            nrImages += parseInt(finalButtonTextMatches[1])
            finalButton.innerText = finalButton.innerText.replace(/\((.+?)\)/g, "("+nrImages+")")
        }
    }

})()

