ActionCable = require('actioncable');
var cable = ActionCable.createConsumer();

import Vue from 'vue';
window.Vue = Vue;

require('./load_axios.js');

import VueRouter from 'vue-router';
Vue.use(VueRouter);
import Vuex from 'vuex';
Vue.use(Vuex);
import { store } from './store/main.js';

import routes from './routes';
const router = new VueRouter({
  mode: 'history',
  routes: routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
});

const moment = require('moment');
Vue.prototype.$moment = moment;


let app = null;
import Admin from '../components/admin';
document.addEventListener('DOMContentLoaded', () => {
  app = new Vue({
    el: '#admin',
    router,
    store,
    data: {
      cable: cable,
    },
    computed: {
      csrf() {
        return document.head.querySelector('meta[name="csrf-token"]').content;
      }
    },
    methods: {
      readableBytes(num) {
        var neg = num < 0;
        var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        if (neg) {
            num = -num;
        }
        if (num < 1) {
            return (neg ? '-' : '') + num + ' B';
        }
        var exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
        num = Number((num / Math.pow(1000, exponent)).toFixed(2));
        var unit = units[exponent];
        return (neg ? '-' : '') + num + ' ' + unit;
      },
    },
    render: h => h(Admin),
    mounted() {
      window.addEventListener('focus', () => this.$store.dispatch('focus/focus') );
      window.addEventListener('blur', () => this.$store.dispatch('focus/unFocus') );
    }
  });
})

import Hidden from '../components/hidden';
document.addEventListener('DOMContentLoaded', () => {
  if(document.querySelector("#app")) {
    app = new Vue({
      el: '#app',
      router,
      data: {
        cable: cable,
      },
      computed: {
        csrf() {
          return document.head.querySelector('meta[name="csrf-token"]').content;
        }
      },
      render: h => h(Hidden),
      mounted() {
        console.log("this mounted");
        this.cable.subscriptions.create({
          channel: "PresenceChannel"
        }, {
        });
      }
    });
  }
})

import "./firebase/fileUpload.js"
import "./firebase/getFiles.js"
