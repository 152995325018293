<template>
  <div id="clients-management">
    <Clients route="adminClient"></Clients>
    <router-view name="client"></router-view>
  </div>
</template>

<script>
import Clients from './clients/clients.vue';
import Top from './menus/Top.vue';
export default {
  components: {
    Clients, Top
  },
  data: function () {
    return {
    }
  },
  mounted() {
  }
}
</script>
