// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCngWMI50_ZHDA9UYhk55TzobH6yMyk1NM",
    authDomain: "blat-unum.firebaseapp.com",
    databaseURL: "https://blat-unum-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "blat-unum",
    storageBucket: "blat-unum.appspot.com",
    messagingSenderId: "947432604229",
    appId: "1:947432604229:web:a377ac57436ff19207592c",
    measurementId: "G-VJ54LJM723"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

import { getStorage, connectStorageEmulator } from "firebase/storage";
const storage = getStorage();
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
const firestore = getFirestore();

if (location.hostname === "localhost") {
    console.log('Connecting to emulators')
    connectStorageEmulator(storage, "127.0.0.1", 9199);
    connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
} 

export {app}

