var clients = {
    namespaced: true,
    state: {
        all: [],
    },
    mutations: {
        all(state, companies) {
            state.all = companies;
        }
    },
    actions: {
        fetchAll({ commit, state }) {
            axios.get('/clients')
            .then( response => commit('all', response.data) );
        }
    },
    getters: {
        client: (state) => (client_id) => {
            return state.all.find( ({id}) => client_id == id );
        },
    }
}
 
export default clients;