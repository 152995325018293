<template>
  <div id="projects">
    <h3 class="title">Projetos</h3>
    <nav class="filter">
      <router-link :to="{name: $route.name, query: query}" :class="{closed: !query.closed}">
        <icon icon="box"></icon>
      </router-link>
    </nav>
    <div class="menu">
      <scroll @scroll="fetchProjects" ref="scroll">
        <ul class="menu" v-if="projects">
          <client-project v-for="project in projects" :key="project.id" :project="project">
          </client-project>
          <li class="loading" ref="loading" v-if="!complete">
            A carregar projetos...
          </li>
        </ul>
      </scroll>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Scroll from '../Scroll.vue';
import Icon from '../SvgIcon.vue';
import ClientProject from './ClientProject.vue';

export default {
  components: {
    Scroll, ClientProject, Icon
  },
  props: {
    client_id: {required: true}
  },
  data() {
    return {
      updateInterval: null,
      requestIndex: 0,
      dispatched: false,
      channelSubscription: null,
    }
  },
  computed: {
    ...mapGetters({
      clientProjects: 'projects/client',
      completeProjects: 'projects/complete',
      clientById: 'clients/client',
      messages: 'messages/project',
    }),
    client() { return this.clientById(this.client_id) },
    closed() {
      return !this.query.closed;
    },
    complete() {
      return this.completeProjects(this.client_id, this.closed);
    },
    projects() {
      return this.clientProjects(this.client_id, this.closed).sort( (a,b) => {
        let aUpdate = this.$moment(a.updated_at);
        let bUpdate = this.$moment(b.updated_at);
        return bUpdate.diff(aUpdate);
      })
    },
    query() {
      let closed = 1;
      if( this.$route.query.closed && parseInt(this.$route.query.closed)) {
        closed = undefined;
      }
      return { ...this.$route.query, closed: closed };
    },
  },
  watch: {
    closed() {
      clearTimeout(this.dispatched);
      this.dispatched = false;
      this.$nextTick().then(() => this.fetchProjects())
    },
    client_id() {
      clearTimeout(this.dispatched);
      this.dispatched = false;
      this.subscribe();
      this.$nextTick().then(() => this.fetchProjects())
    }
  },
  methods: {
    fetchMessages() {
    },
    fetchProjects() {
      if( (!this.dispatched) && this.loadingVisible() ) {
        this.dispatched = setTimeout(() => {
          this.dispatched = false;
          this.fetchProjects();
        }, 750);
        this.requestIndex = 0;
        this.$store.dispatch('projects/fetchClient', {id: this.client_id, closed: this.closed});
      }
    },
    loadingVisible() {
      if( this.complete ) {
        return false;
      }
      let el = this.$refs.scroll.$el;
      let loadingEl = this.$refs.loading;
      // scrolled height + visible height
      let bottomPosition = el.scrollTop + el.clientHeight; // Last visible position
      // scrollable height - loading element height
      let loadingPosition = el.scrollHeight - loadingEl.clientHeight; // loading element position
      return loadingPosition < bottomPosition;
    },
    subscribe() {
      if( this.channelSubscription != null ) {
        this.$root.cable.subscriptions.remove(this.channelSubscription);
      }
      let vueProjects = this;
      this.channelSubscription = this.$root.cable.subscriptions.create({
        channel: "ProjectsChannel",
        client_id: this.client_id,
      },{
        received(data) {
          vueProjects.$store.dispatch('projects/fetch', {id: data.id});
        }
      })
    },
  },
  mounted() {
    this.subscribe();
    this.fetchProjects();
    this.updateInterval = setInterval(() => {
      if( this.projects && this.projects.length > 0 ) {
        let project = this.projects[this.requestIndex];
        this.requestIndex = (this.requestIndex + 1)%this.projects.length;
        if( this.messages(project).length == 0 ) {
          this.$store.dispatch('messages/fetch', {project: project, params: {limit: 1}});
        }
      }
    }, 300);
  },
  beforeDestroy() {
    this.$root.cable.subscriptions.remove(this.channelSubscription);
  },
  destroyed() {
    clearInterval(this.updateInterval);
  },
}
</script>