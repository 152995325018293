var projects = {
    namespaced: true,
    state: {
        projects: [],
        views: [],
        completeClosed: [],
        completeOpen: [],
    },
    mutations: {
        addViews(state, views) {
            views.forEach( view => {
                let index = state.views.findIndex( ({id}) => id == view.id );
                if( index > -1 ) {
                    state.views.splice(index, 1);
                }
                state.views.push(view);
            });
        },
        clientProjects(state, {id, projects, closed, requested}) {
            let theArray = closed ? state.completeClosed : state.completeOpen;
            if( requested != null && projects.length < requested ) {
                theArray.push(id);
            } else if(id == null) {
                let ids = projects.map( project => project.company_id );
                ids = ids.filter((id, index) => ids.indexOf(id) === index);
                theArray.push(...ids);
            }
            projects.forEach( project => {
                let index = state.projects.findIndex( ({id}) => id == project.id );
                if( index > -1 ) {
                    state.projects.splice(index, 1);
                }
                state.projects.push(project);
            });
        },
        updateProject(state, project) {
            let index = state.projects.findIndex( ({id}) => id == project.id );
            if( index > -1 ) {
                state.projects.splice(index, 1);
            }
            state.projects.push( project );
        },
    },
    actions: {
        fetch({commit}, project) {
            axios.get(`/api/projects/${project.id}`)
            .then( response => commit('updateProject', response.data));
        },
        fetchViews({commit}, {id}) {
            axios.get(`/api/projects/${id}/views`)
            .then( response => commit('addViews', response.data));
        },
        fetchOpen({ commit, state }) {
            let projects = state.projects.map(project => project.id);
            let params = {
                closed: closed,
                ids: projects,
            };
            axios.get('/api/projects/', {params: params})
            .then( response => commit('clientProjects', {projects: response.data, closed: closed}) );
        },
        fetchClient({ commit, getters }, {id, closed, limit = 10}) {
            let projects = getters.client(id, closed).map(project => project.id);
            let params = {
                closed: closed,
                limit: limit,
                ids: projects,
            };
            axios.get(`/clients/${id}/projects`, {params: params})
            .then( response => commit('clientProjects', {id: id, projects: response.data, closed: closed, requested: limit}) );
        },
        convert({commit}, project) {
            axios.post(`/api/projects/${project.id}/convert`)
            .then( response => commit('updateProject', response.data));
        },
        notify({commit}, project) {
            axios.post(`/api/projects/${project.id}/notify`)
            .then( response => commit('updateProject', response.data));
        },
        viewed({commit}, project) {
            axios.patch(`/api/projects/${project.id}/viewed`)
            .then( response => commit('updateProject', response.data));
        },
        archive({commit}, project) {
            axios.patch(`/api/projects/${project.id}`, {project: {closed: !project.closed}})
            .then( response => commit('updateProject', response.data));
        },
    },
    getters: {
        client: (state) => (id, is_closed) => {
            if( is_closed == null ) { is_closed = false };
            return state.projects.filter( ({company_id, closed}) => company_id == id && closed == is_closed );
        },
        complete: (state) => (client_id, is_closed) => {
            let complete = is_closed ? state.completeClosed : state.completeOpen;
            return complete.includes(client_id);
        },
        project: (state) => (project_id) => {
            return state.projects.find( ({id}) => id == project_id );
        },
        projectViews: (state) => (id) => {
            return state.views.filter( ({project_id}) => id == project_id );
        },
        clientUnread: (state) => (client_id) => {
            return state.projects.filter(
                ({company_id, closed, unread}) => company_id == client_id && closed == false && unread > 0
            );
        },
        unread: (state, getters) => (client_id) => {
            return getters.client(client_id, false).reduce((t, cv, ci, arr) => t + cv.unread,0)
        }
    }
}
 
export default projects;