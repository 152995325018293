<template>
  <div id="project-management">
    <Clients></Clients>
    <router-view name="projects"></router-view>
    <router-view name="messages"></router-view>
    <router-view name="files" ref="files"></router-view>
    <router-view name="information"></router-view>
    <router-view name="preview"></router-view>
  </div>
</template>

<script>
import Clients from './clients/clients.vue';
export default {
  components: {
    Clients
  },
  data: function () {
    return {
    }
  },
  mounted() {
  }
}
</script>
