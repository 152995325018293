<template>
<div v-if="client" class="contacts-index">
  <table>
    <tbody>
      <tr :key="contact.id" v-for="contact in contacts">
        <td v-text="contact.name"></td>
        <td v-text="contact.email"></td>
        <td class="icon delete" @click="destroy(contact)">
          <svg-icon icon="trash"></svg-icon>
        </td>
      </tr>
      <tr>
        <td class="input">
          <input type="text" placeholder="Nome" v-model="name" @keydown="keydown" ref="name">
        </td>
        <td class="input">
          <input type="text" placeholder="Email" v-model="email" @keydown="keydown">
        </td>
        <td class="icon add no-hide" @click="createContact">
          <svg-icon icon="plus"></svg-icon>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import SvgIcon from '../SvgIcon.vue';

export default {
  components: {
    SvgIcon
  },
  props: {
    client_id: {required: true},
  },
  data() {
    return {
      name: '',
      email: '',
    }
  },
  computed: {
    ...mapGetters({
      clientById: 'clients/client',
      contactsByClient: 'contacts/client',
    }),
    client() {
      return this.clientById(this.client_id);
    },
    contacts() {
      return this.contactsByClient(this.client_id);
    },
  },
  methods: {
    createContact() {
      let params = {
        contact: {
          name: this.name,
          email: this.email,
        }
      }
      this.name = '';
      this.email = '';
      this.$store.dispatch('contacts/create', {
        id: this.client_id,
        params,
      });
      this.$refs.name.focus();
    },
    destroy(contact) {
      this.$store.dispatch('contacts/destroy', contact)
    },
    submit() {
      console.log('should submit');
    },
    keydown(e) {
      switch(e.key) {
        case "Enter":
          if( !(e.shiftKey || e.ctrlKey || e.altKey) ) {
            e.preventDefault();
            this.createContact();
          }
          break;
      }
    },
  },
  watch: {
    client_id() {
      this.$store.dispatch('contacts/fetch', this.client_id);
    }
  },
  mounted() {
    this.$store.dispatch('contacts/fetch', this.client_id);
  },
  beforeDestroy() {
  },
}
</script>