<template>
  <nav class="menu" id="topbar">
    <router-link :to="{name: 'admin'}" class="home">
      <image-loader :src="logoUrl" alt="dev"></image-loader>
      DEV
    </router-link>
    <div class="menu">
      <ul v-if="topMenu">
        <router-link tag="li" v-for="item in topMenu.items" :key="item.title" :to="item.to">
          <a v-text="item.title"></a>
        </router-link>
        <a href="/users/users">Users</a>
      </ul>
    </div>
    <div class="right">
      <ul>
        <li>
          <a href="#" @click.prevent="logout" class="alert">
            <svg-icon icon="logout">
            </svg-icon>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import Scroll from '../Scroll.vue';
import ImageLoader from '../ImageLoader.vue';
import Icon from 'images/white_icon_48x48.png';
import SvgIcon from '../SvgIcon.vue';

export default {
  components: {
    Scroll, ImageLoader, SvgIcon
  },
  props: {
  },
  data() {
    return {
      collapsed: false,
      menus: [],
    }
  },
  computed: {
    ...mapGetters({
      unreadProjects: 'projects/clientUnread',
    }),
    logoUrl() {
      return Icon;
    },
    topMenu() {
      return this.menus.find( menu => menu.name == 'top');
    },
  },
  methods: {
    unread(client) {
      return this.unreadProjects(client.id).length;
    },
    subscribe() {
      let vue = this;
      if( this.notificationsSubscription != null ) {
        this.$root.cable.subscriptions.remove(this.notificationsSubscription);
      }
      this.notificationsSubscription = this.$root.cable.subscriptions.create({
        channel: "NotificationsChannel"
      }, {
        received(data) {
          if( data.type ) {
            switch (data.type) {
              case "message":
                vue.$store.commit('messages/add', data.message);
                vue.$store.dispatch('projects/fetch', {id: data.message.project_id});
                break;

              case "delete":
                vue.$store.commit('messages/remove', data.message);
                break;
            
              default:
                break;
            }
          }
        }
      })
    },
    logout() {
      var getUrl = window.location;
      axios.delete('/users/sign_out')
      .then( response => window.location = `${getUrl.protocol}//${getUrl.host}/`)
    },
  },
  mounted() {
    this.subscribe();
    console.log('toptop');
    axios.get('/api/menus')
    .then( response => this.menus = response.data );
  },
  beforeDestroy() {
    this.$root.cable.subscriptions.remove(this.notificationsSubscription);
  },
}
</script>