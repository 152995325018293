import axios from '../load_axios'
import {app} from '../firebase/fb'

import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';

const storage = getStorage(app)

var messages = {
    namespaced: true,
    state: {
        all: [],
        complete: [],
        uploads: [],
    },
    mutations: {
        addMultiple(state, messages) {
            for (let index = 0; index < messages.length; index++) {
                let message = messages[index];
                let allIndex = state.all.findIndex( ({id}) => id == message.id );
                if( allIndex > -1 ) {
                    state.all.splice(allIndex, 1);
                }
                state.all.push(message);
            }
        },
        add(state, message) {
            let allIndex = state.all.findIndex( ({id}) => id == message.id );
            if( allIndex > -1 ) {
                state.all.splice(allIndex, 1);
            }
            state.all.push(message);
        },
        addUpload(state, {message, file, status = 'queue', progress = 0}) {
            let index = state.uploads.findIndex( (element) => element.file === file );
            if( index > -1 ) {
                state.uploads.splice(index, 1);
            }
            if( status != 'uploaded') {
                state.uploads.push({
                    message_id: message.id,
                    file: file,
                    status: status,
                    progress: progress,
                });
            }
        },
        remove(state, message) {
            let allIndex = state.all.findIndex( ({id}) => id == message.id );
            if( allIndex > -1 ) {
                state.all.splice(allIndex, 1);
            }
        },
        completeProject(state, project) {
            state.complete.push(project.id);
        },
    },
    actions: {
        fetch({ commit, state }, {project, params}) {
            axios.get(`${project.url}/messages`, {params: params})
            .then( response => commit('addMultiple', response.data) );
        },
        fetchMore({commit, getters}, {project}) {
            let oldest_message = getters.oldest(project);
            let params = {};
            if(oldest_message) {
                params.last_id = oldest_message.id;
            }
            axios.get(`${project.url}/messages`, {params: params})
            .then( response => {
                if( response.data.length < 10 ) {
                    commit('completeProject', project);
                }
                commit('addMultiple', response.data) 
            });
        },
        async create({commit, getters}, {project, message, uploads}) {
            try {
                let response = await axios.post(`${project.url}/messages`, {
                    message: {body: message}
                })
                let newMessage = response.data;
                commit('add', newMessage);
                for( let i in uploads ) {
                    commit('addUpload', {file: uploads[i].file, message:  newMessage});
                }
                let queue = getters.uploads(newMessage);
                for( let i in queue) {
                    let element = queue[i];
                    let file = element.file;
                    let formData = new FormData();
                    formData.append('message[attachments][]', file);
                    commit('addUpload', {file: file, message:  newMessage, status: 'uploading'});

                    const projectId = project.id
                    const storageRef = ref(storage, `projects/${projectId}/new/${file.name}`)
                    const uploadTask = uploadBytesResumable(storageRef, file);

                    uploadTask.on('state_changed', (snapshot) => {
                        let progress = Math.round(snapshot.bytesTransferred * 100 / snapshot.totalBytes)
                        if( progress == 100 ) {
                            commit('addUpload', {file, message: newMessage, status: 'uploaded', progress})
                            return
                        }
                        switch(snapshot.state) {
                            case 'paused':
                                commit('addUpload', {file, message: newMessage, status: 'paused', progress})
                                break
                            case 'running':
                                commit('addUpload', {file, message: newMessage, status: 'uploading', progress})
                                break
                        }
                    }, (error) => {
                        switch(error.code) {
                            case 'storage/unauthorized':
                                commit('addUpload', {file, message: newMessage, status: 'Unauthorized'})
                                break
                            case 'storage/canceled':
                                commit('addUpload', {file, message: newMessage, status: 'Canceled'})
                                break
                            default:
                                commit('addUpload', {file, message: newMessage, status: 'Failed'})
                                break
                        }
                    })
                }
            } catch {
            }
        },
        delete({commit}, message) {
            axios.delete(`/api/messages/${message.id}`).then( () => {
                commit('remove', message);
            })
            .catch( error => console.log(error));
        },
    },
    getters: {
        oldest: (state, getters) => (project) => {
            let messages = getters.project(project);
            let message = null;
            for (let index = 0; index < messages.length; index++) {
                if( message == null || message.id > messages[index].id ) {
                    message = messages[index];
                }
            }
            return message;
        },
        last: (state, getters) => (project) => {
            let messages = getters.project(project);
            let message = null;
            for (let index = 0; index < messages.length; index++) {
                if( message == null || message.id < messages[index].id ) {
                    message = messages[index];
                }
            }
            return message;
        },
        project: (state) => (project) => {
            return state.all.filter( ({project_id}) => project_id == project.id );
        },
        complete: (state) => (project) => {
            return state.complete.includes(project.id);
        },
        uploads: (state) => (message) => {
            return state.uploads.filter( ({message_id}) => message_id === message.id);
        }
    }
}
 
export default messages;
